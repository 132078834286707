import type { AppProps } from "next/app";
// import { Layout } from "@components/Layout";
import "../styles/globals.css";
import { useEffect } from "react";
import { getAnalytics } from "firebase/analytics";
import { app } from "../firebase/firebase";
import { AuthContextProvider } from "../utils/AuthContext";
import { useRouter } from "next/router";
import Script from "next/script";
import BingConversionScript from "../components/BingConversionScript/BingConversionScript";
import '../styles/new.css'

function MyApp({ Component, pageProps }: AppProps) {
  const router = useRouter();
  useEffect(() => {
    if (process.env.NODE_ENV === "production") {
      getAnalytics(app);
    }
  }, []);

  useEffect(() => {
    import("react-facebook-pixel")
      .then((x) => x.default)
      .then((ReactPixel) => {
        ReactPixel.init("3255086221452683");
        ReactPixel.pageView();

        router.events.on("routeChangeComplete", () => {
          ReactPixel.pageView();
        });
      });
  }, [router.events]);

  return (
    <>
      <Script src="https://www.googletagmanager.com/gtag/js?id=AW-10784667441" />
      <Script id="g-tag">{`window.dataLayer = window.dataLayer || []; function gtag(){dataLayer.push(arguments);} gtag('js', new Date()); gtag('config', 'AW-10784667441');`}</Script>
      <BingConversionScript />

      <AuthContextProvider>
        <Component {...pageProps} />
      </AuthContextProvider>
    </>
  );
}

export default MyApp;
