import React, { createContext, useContext, useEffect, useState } from "react";
import { useRouter } from "next/router";
import {
  createUserWithEmailAndPassword,
  GoogleAuthProvider,
  onAuthStateChanged,
  sendEmailVerification,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut,
  User,
} from "firebase/auth";
import { auth, db } from "../firebase/firebase";
import { doc, serverTimestamp, setDoc } from "firebase/firestore";
import axios from "axios";
import { EXCEL_BOT_TOKEN } from "./constants";

export type AuthContextType = {
  user: User | null | undefined;
  loading: boolean;
  signUpWithEmailPassword: (
    email: string,
    password: string
  ) => Promise<User | undefined>;
  logInWithEmailPassword: (email: string, password: string) => Promise<User>;
  logOut: () => void;
  authWithGoogle: (isLogin: boolean) => Promise<User | undefined>;
};

const AuthContext = createContext({} as AuthContextType);

export const useUser = () => useContext<AuthContextType>(AuthContext);

export const AuthContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const router = useRouter();
  const [user, setUser] = useState<User | null>();
  const [loading, setLoading] = useState<boolean>(true);
  const [fromEngine, setFromEngine] = useState<string | null>(null);
  const [engineClickId, setEngineClickId] = useState<string | null>(null);

  const query = router.query;

  //auth providers
  const googleProvider = new GoogleAuthProvider();

  useEffect(() => {
    const googleClickId = query.gclid as string
    const bingClickId = query.msclkid as string

    if (googleClickId) {
      setFromEngine('Google')
      setEngineClickId(googleClickId)
      localStorage.setItem('fromEngine', 'Google')
      localStorage.setItem('engineClickId', googleClickId)
    } else if (bingClickId) {
      setFromEngine('Bing')
      setEngineClickId(bingClickId)
      localStorage.setItem('fromEngine', 'Bing')
      localStorage.setItem('engineClickId', bingClickId)
    } else {
      const fromEngineStorage = localStorage.getItem('fromEngine')
      const engineClickIdStorage = localStorage.getItem('engineClickId')

      if (fromEngineStorage) {
        setFromEngine(fromEngineStorage)
      }

      if (engineClickIdStorage) {
        setEngineClickId(engineClickIdStorage)
      }
    }
  }, [query])

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      // console.log(user);
      if (user) {
        setUser(user);
      } else {
        setUser(null);
      }
    });

    const tokenRefreshInterval = 300000;
    const tokenRefreshTimer = setInterval(async () => {
      const currentUser = auth.currentUser

      if (currentUser) {
        const tokenResult = await currentUser.getIdTokenResult();

        if (tokenResult) {
          const tokenExpirationTime = new Date(tokenResult.expirationTime).getTime();
          const currentTime = new Date().getTime();

          const timeToExpiration = tokenExpirationTime - currentTime;

          if (timeToExpiration < tokenRefreshInterval) {
            try {
              const newToken = await currentUser.getIdToken(true);

              setUser({
                ...user,
                accessToken: newToken
              } as User)
            } catch (error) {
              console.log('Error refreshing ID token:', error);
            }
          }
        }
      }
    }, 100000);

    setLoading(false);

    return () => {
      unsubscribe();
      clearInterval(tokenRefreshTimer)
    }
  }, []);

  //auth functions
  const authWithGoogle = async (isLogin: boolean) => {
    const res = await signInWithPopup(auth, googleProvider);
    const user = res.user;
    if (user && !isLogin) {
      await axios.post(
        "/api/signup",
        {
          photoUrl: user.photoURL,
          email: user.email,
          displayName: user.displayName,
          providerId: user.providerData[0].providerId,
          trafficSource: fromEngine,
          trafficClickId: engineClickId,
        },
        {
          headers: {
            // @ts-ignore
            Authorization: `Bearer ${user.accessToken}`,
            ExcelBotToken: EXCEL_BOT_TOKEN,
          },
        }
      );

      setFromEngine(null)
      setEngineClickId(null)
      localStorage.removeItem('fromEngine')
      localStorage.removeItem('engineClickId')

      const emailData = {
        email: user.email
      };

      fetch('/api/sendEmail', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(emailData),
      })
        .then(response => {
          if (!response.ok) {
            throw new Error('Failed to send email');
          }
          return response.json();
        })
        .then(data => {
          console.log('Email sent successfully', data);
        })
        .catch(error => {
          console.error('Error sending email:', error);
        });

    }
    return user;
  };

  const signUpWithEmailPassword = async (email: string, password: string) => {
    const res = await createUserWithEmailAndPassword(auth, email, password);
    const user = res.user;
    if (user) {
      const userRef = doc(db, "users", user.uid);
      await sendEmailVerification(user);

      await axios.post(
        "/api/signup",
        {
          photoUrl: `https://api.multiavatar.com/${user.uid}.png?apikey=${process.env.NEXT_PUBLIC_MULTIAVATAR_API_KEY}`,
          email: user.email,
          displayName: null,
          providerId: user.providerData[0].providerId,
          trafficSource: fromEngine,
          trafficClickId: engineClickId,
        },
        {
          headers: {
            // @ts-ignore
            Authorization: `Bearer ${user.accessToken}`,
            ExcelBotToken: EXCEL_BOT_TOKEN,
          },
        }
      );

      setFromEngine(null)
      setEngineClickId(null)
      localStorage.removeItem('fromEngine')
      localStorage.removeItem('engineClickId')

      const emailData = {
        email: user.email
      };

      fetch('/api/sendEmail', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(emailData),
      })
        .then(response => {
          if (!response.ok) {
            throw new Error('Failed to send email');
          }
          return response.json();
        })
        .then(data => {
          console.log('Email sent successfully', data);
        })
        .catch(error => {
          console.error('Error sending email:', error);
        });

    }
    return user;
  };

  const logInWithEmailPassword = async (email: string, password: string) => {
    const res = await signInWithEmailAndPassword(auth, email, password);
    return res.user;
  };

  const logOut = async () => {
    setUser(null);
    await signOut(auth);
    router.push("/");
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        loading,
        signUpWithEmailPassword,
        logInWithEmailPassword,
        logOut,
        authWithGoogle,
      }}
    >
      {loading ? null : children}
    </AuthContext.Provider>
  );
};
